// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file vultisig/vault/v1/vault.proto (package vultisig.vault.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
	BinaryReadOptions,
	FieldList,
	JsonReadOptions,
	JsonValue,
	PartialMessage,
	PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';

/**
 * @generated from message vultisig.vault.v1.Vault
 */
export class Vault extends Message<Vault> {
	/**
	 * @generated from field: string name = 1;
	 */
	name = '';

	/**
	 * @generated from field: string public_key_ecdsa = 2;
	 */
	publicKeyEcdsa = '';

	/**
	 * @generated from field: string public_key_eddsa = 3;
	 */
	publicKeyEddsa = '';

	/**
	 * @generated from field: repeated string signers = 4;
	 */
	signers: string[] = [];

	/**
	 * @generated from field: google.protobuf.Timestamp created_at = 5;
	 */
	createdAt?: Timestamp;

	/**
	 * @generated from field: string hex_chain_code = 6;
	 */
	hexChainCode = '';

	/**
	 * @generated from field: repeated vultisig.vault.v1.Vault.KeyShare key_shares = 7;
	 */
	keyShares: Vault_KeyShare[] = [];

	/**
	 * @generated from field: string local_party_id = 8;
	 */
	localPartyId = '';

	/**
	 * @generated from field: string reshare_prefix = 9;
	 */
	resharePrefix = '';

	constructor(data?: PartialMessage<Vault>) {
		super();
		proto3.util.initPartial(data, this);
	}

	static readonly runtime: typeof proto3 = proto3;
	static readonly typeName = 'vultisig.vault.v1.Vault';
	static readonly fields: FieldList = proto3.util.newFieldList(() => [
		{ no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
		{
			no: 2,
			name: 'public_key_ecdsa',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
		},
		{
			no: 3,
			name: 'public_key_eddsa',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
		},
		{
			no: 4,
			name: 'signers',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
			repeated: true,
		},
		{ no: 5, name: 'created_at', kind: 'message', T: Timestamp },
		{
			no: 6,
			name: 'hex_chain_code',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
		},
		{
			no: 7,
			name: 'key_shares',
			kind: 'message',
			T: Vault_KeyShare,
			repeated: true,
		},
		{
			no: 8,
			name: 'local_party_id',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
		},
		{
			no: 9,
			name: 'reshare_prefix',
			kind: 'scalar',
			T: 9 /* ScalarType.STRING */,
		},
		{
			no: 10,
			name: 'local_party_id',
			kind: 'bytes',
			T: 6 /* ScalarType.BYTES */,
		}
	]);

	static fromBinary(
		bytes: Uint8Array,
		options?: Partial<BinaryReadOptions>
	): Vault {
		return new Vault().fromBinary(bytes, options);
	}

	static fromJson(
		jsonValue: JsonValue,
		options?: Partial<JsonReadOptions>
	): Vault {
		return new Vault().fromJson(jsonValue, options);
	}

	static fromJsonString(
		jsonString: string,
		options?: Partial<JsonReadOptions>
	): Vault {
		return new Vault().fromJsonString(jsonString, options);
	}

	static equals(
		a: Vault | PlainMessage<Vault> | undefined,
		b: Vault | PlainMessage<Vault> | undefined
	): boolean {
		return proto3.util.equals(Vault, a, b);
	}
}

/**
 * @generated from message vultisig.vault.v1.Vault.KeyShare
 */
export class Vault_KeyShare extends Message<Vault_KeyShare> {
	/**
	 * @generated from field: string public_key = 1;
	 */
	publicKey = '';

	/**
	 * @generated from field: string keyshare = 2;
	 */
	keyshare = '';

	constructor(data?: PartialMessage<Vault_KeyShare>) {
		super();
		proto3.util.initPartial(data, this);
	}

	static readonly runtime: typeof proto3 = proto3;
	static readonly typeName = 'vultisig.vault.v1.Vault.KeyShare';
	static readonly fields: FieldList = proto3.util.newFieldList(() => [
		{ no: 1, name: 'public_key', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
		{ no: 2, name: 'keyshare', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
	]);

	static fromBinary(
		bytes: Uint8Array,
		options?: Partial<BinaryReadOptions>
	): Vault_KeyShare {
		return new Vault_KeyShare().fromBinary(bytes, options);
	}

	static fromJson(
		jsonValue: JsonValue,
		options?: Partial<JsonReadOptions>
	): Vault_KeyShare {
		return new Vault_KeyShare().fromJson(jsonValue, options);
	}

	static fromJsonString(
		jsonString: string,
		options?: Partial<JsonReadOptions>
	): Vault_KeyShare {
		return new Vault_KeyShare().fromJsonString(jsonString, options);
	}

	static equals(
		a: Vault_KeyShare | PlainMessage<Vault_KeyShare> | undefined,
		b: Vault_KeyShare | PlainMessage<Vault_KeyShare> | undefined
	): boolean {
		return proto3.util.equals(Vault_KeyShare, a, b);
	}
}